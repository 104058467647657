
import { Component, Vue, Watch } from 'vue-property-decorator'
import GoodsPane from '@/components/goods/goods-pane.vue'
import LsDialog from '@/components/ls-dialog.vue'
import ExportData from '@/components/export-data/index.vue'
import { RequestPaging } from '@/utils/util'
import { apiGoodsLists, apiGoodsOtherList, apiGetShop, apiAsyncGoodsAll, apiCopyGoodsAll, apiExportGoods } from '@/api/goods'
import { GoodsType } from '@/utils/type'
@Component({
    components: {
        LsDialog,
        GoodsPane,
        ExportData
    }
})
export default class Goods extends Vue {
    queryObj: any = {
        keyword: '',
        category_id: '',
        supplier_id: '',
        goods_type: '',
        activity_type: ''
    }
    supplierList: any[] = []
    categoryList: any[] = []
    typeList: any = []
    activityList: any = []
    tabCount = {
        all_count: 0,
        sales_count: 0,
        sellout_count: 0,
        storage_count: 0,
        warning_count: 0
    }
	is_add_goods: any = 1
    pager = new RequestPaging()
    activeName: any = 'all'
	asynctext = "1. 将saas端所有商品的上下架状态同步到所有子商城 \n\n 2. 如果子商城禁止删除saas商品至选品库，则会将所有saas端所有商品同步到子商城（如果子商城没有商品则会创建） \n\n 3. 如果子商城允许删除saas商品至选品库，如果商品已存在于子商城，则更新此商品信息（包括上下架状态），如果子商城没有此商品，则不会有任何操作！"
    copytext = "将saas端所有商品复制到所有的子商城，请谨慎操作！"
	apiGoodsLists = apiGoodsLists
	apiExportGoods = apiExportGoods
    getList(page?: number): void {
        this.queryObj.type_lists = GoodsType[this.activeName]
        page && (this.pager.page = page)
        this.pager
            .request({
                callback: apiGoodsLists,
                params: {
                    // type: GoodsType[this.activeName],
                    ...this.queryObj
                }
            })
            .then((res: any) => {
                this.tabCount = res?.extend
            })
    }
    handleReset() {
        this.queryObj = {
            keyword: '',
            category_id: '',
            supplier_id: '',
            goods_type: '',
            activity_type: ''
        }
        this.getList()
    }
	
	asyncAll(){
		apiAsyncGoodsAll({}).then((res: any)=>{
		})
	}
	
	copyAll(){
		apiCopyGoodsAll({}).then((res: any)=>{
		})
	}
	
	getShopData(){
		apiGetShop({}).then((res: any)=>{
			this.is_add_goods = res.is_add_goods
		})
	}

    getGoodsOtherList() {
        apiGoodsOtherList({
            type: 'list'
        }).then((res: any) => {
            this.categoryList = res?.category_list
            this.supplierList = res?.supplier_list
            this.typeList = res?.type_list
            this.activityList = res?.activity_list
        })
    }
    created() {
        this.getGoodsOtherList()
        this.getList()
		this.getShopData()
    }
    activated() {
        this.getGoodsOtherList()
        this.getList()
    }
}
