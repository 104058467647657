
import { Component, Prop, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import PopoverInput from '@/components/popover-input.vue'
import { apiGoodsDel, apiGoodsRename, apiGoodsSort, apiGoodsStatus, apiMoveCategory, apiGetMainGoods, apiGoodsDetail, apiGoodsRestock } from '@/api/goods'

@Component({
    components: {
        LsDialog,
        LsPagination,
        PopoverInput
    }
})
export default class GoodsPane extends Vue {
    $refs!: { paneTable: any }
    @Prop() value: any
    @Prop() pager!: any
    @Prop() isaddgoods!: any
    @Prop() categoryList!: any
    status = true
    selectIds: any[] = []
    categoryIds: number[] = []
	
	stockGoodsName = "库存"
	stockGoodsId = 0
	dialogFormVisible = false
	stockLists: any[] = []

    get disabledBtn() {
        return !this.selectIds.length
    }

	aaa(){
		console.log("aaaa");
	}
    // 批量上架/下架商品
    handleBatchStatus({ status, ids }: any) {
        apiGoodsStatus({
            ids: ids ? ids : this.selectIds,
            status
        }).then(() => {
            this.$emit('refresh')
        })
    }
    // 批量删除商品
    handleBatchDelete(ids: any) {
        apiGoodsDel({
            ids: Array.isArray(ids) ? ids : this.selectIds
        }).then(() => {
            this.$emit('refresh')
        })
    }
    handleMoveCategory() {
        apiMoveCategory({
            ids: this.selectIds,
            category_id: this.categoryIds
        }).then(() => {
            this.$emit('refresh')
        })
    }

    handleSelect(val: any[]) {
        this.selectIds = val.map(item => item.id)
    }
    // 全选商品
    handleselectAll() {
        this.$refs.paneTable.toggleAllSelection()
    }

    handleSort(sort: string, id: number) {
        apiGoodsSort({
            id,
            sort
        }).then(() => {
            this.$emit('refresh')
        })
    }
    handleRename(val: string, id: number) {
        apiGoodsRename({
            id,
            name: val
        }).then(() => {
            this.$emit('refresh')
        })
    }
	getMainGoods(){
		const loading = this.$loading({
			lock: true,
			text: '拉取商品中...',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)',
		});
		apiGetMainGoods({
		}).then((res) => {
			loading.close();
		    this.$emit('refresh')
		})
	}
	getStock(id: number){
		this.stockGoodsId = id;
		apiGoodsDetail(id).then((res) => {
			this.stockGoodsName = "库存 ( "+res.name+" )"
			this.stockLists = res.spec_value_list
			this.dialogFormVisible = true;
		})
	}
	handkeRestock(val: string, id: number) {
        apiGoodsRestock({
            id,
            stock: val
        }).then(() => {
			this.getStock(this.stockGoodsId);
			this.$emit('refresh')
        })
    }
}
